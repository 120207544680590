"use strict";

var $ = require("jquery");

var login = function ( ) {
    var pwd = "AGM@ps";
    var res = "";
    var cnt = 0;
    while (res !== pwd) {
        if (cnt > 4) {
            document.location.href = "loginCancelled.html";
            break;
        } else {
            res = prompt("Please enter the password to view this site:", "");
            if (res === null) {
                document.location.href = "loginCancelled.html";
                break;
            } else {
                if (res !== pwd) {
                    alert("That is an incorrect password. Please try again.");
                }
            }
        }
        cnt++;
    }
}

module.exports = {
    login: login
};