"use strict";

var $ = require("jquery");

var loadCountryDialog = function (data, code) {
    if (_.has(data, code)) {
        var dialog = $("#dialog");
        dialog.load("resources/countries/" + code + ".htm", function () {
            $('#tabs').tabs({
                active: 0,
                show: false,
                hide: false
            });
            $('.section').addClass('ui-accordion ui-accordion-icons ui-widget ui-helper-reset')
                .find('h3')
                .addClass('ui-accordion-header ui-helper-reset ui-state-default ui-corner-top ui-corner-bottom')
                .hover(function () {
                    $(this).toggleClass('ui-state-hover');
                }).prepend('<span class="ui-icon ui-icon-triangle-1-e"></span>')
                .click(function () {
                    $(this).toggleClass('ui-accordion-header-active ui-state-active ui-state-default ui-corner-bottom')
                        .find('> .ui-icon')
                        .toggleClass('ui-icon-triangle-1-e ui-icon-triangle-1-s')
                        .end()
                        .next()
                        .toggleClass('ui-accordion-content-active')
                        .slideToggle();
                    return false;
                })
                .next()
                .addClass('ui-accordion-content  ui-helper-reset ui-widget-content ui-corner-bottom')
                .hide();
        });

        dialog.dialog({
            title: "Country",
            closeText: "",
            width: $(window).width() - 60,
            height: $(window).height() - 200,
            resizable: true,
            draggable: true,
            modal: true
        });

        dialog.dialog('open');
    }

}

module.exports = {
    loadCountryDialog: loadCountryDialog
};