"use strict";

require("jquery");
require("jquery-ui-bundle");
require("jvectormap-next");
require("./../../../scripts/jquery-jvectormap-world-mill.js");

require("./../../css/jquery-ui-1.10.3.custom.css");
require("./../../../node_modules/jvectormap-next/jquery-jvectormap.css");
require("./../../css/main.css");

var icon = require("./../../css/images/logo.png");
var _ = require("lodash");
var model = require("./dialog.js");
var login = require("./login.js");

login.login();

$(document).ready(function () {


    var img = $("<img/>").attr("src", icon);
    $("#jqm_title").append(img);
    var h1 = $('<h1 style="font-family: FuturaLight !important">Interactive Map Solution</h1>');
    $("#jqm_title").append(h1);

    var markers = [
        { latLng: [1.3, 103.8], name: 'Singapore', code: 'SG' }
    ];

    $.getJSON("./resources/map.json", function  (data)   {

        $('#worldmap').vectorMap({
            map: 'world_mill',
            backgroundColor: 'white',
            markers: markers,
            regionStyle: {
                initial: {
                    fill: 'rgba(230, 230, 217, 1)',
                    "fill-opacity": 1,
                    stroke: 'none',
                    "stroke-width": 0,
                    "stroke-opacity": 1
                },
                hover: {
                    "fill-opacity": 0.7,
                    cursor: 'pointer'
                },
                selected: {
                    fill: '#ffffff'
                },
                selectedHover: {}
            },
            series: {
                regions: [{
                    values: data,
                    scale: ['#C8EEFF', '#3299CC'],
                    normalizeFunction: 'polynomial'
                }]
            },
            onRegionTipShow: function (e, el, code) {
                el.html(el.html());
            },
            onMarkerClick: function (event, index) {

                model.loadCountryDialog(data, markers[index].code);
            },
            onRegionClick: function (event, code) {

                model.loadCountryDialog(data, code);

            }
        });
    })
        .done(function () {
            $(document).on('keyup', function (evt) {
                if (evt.keyCode === 27) {
                    $("#dialog").dialog('close');
                }
            });

    });

});




